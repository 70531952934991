import React from "react";
import { Button } from "react-bootstrap";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Fab from "../../components/fab";
// import { Container, Button, Link } from "react-floating-action-button";
const BankDetails = () => {
  return (
    <div>
      <Header />{" "}
      <div
        data-aos="fade-down"
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Bank account and Payment gateway details
        </h1>
      </div>
      <main
        id="main"
        data-aos="fade-in"
        style={{
          paddingLeft: "1%",
          paddingRight: "1%",
        }}
      >
        <div
          data-aos="fade-down"
          class="cardStyle"
          style={{ paddingBottom: 25, borderRadius: "30px" }}
        >
          <h2
            id="title"
            className="paragraph"
            style={{ marginBottom: "40px", marginTop: "10px" }}
          >
            Bank details
          </h2>
          <p className="paragraph" style={{ textAlign: "justify" }}>
            {" "}
            <strong style={{ fontSize: "18px" }}>Bank Details:</strong>
            <br />
            Please note that as per the notification of the Ministry of Home
            Affairs, Govt of India, the donations, in Indian Rupee or any other
            currency, of all the foreign citizens (donors holding foreign
            passports) have to be credited into the FCRA account with the State
            Bank of India, Parliament Street, New Delhi and the donations of
            Indian Citizens should not be credited in that FCRA Account, but has
            to be credited into Domestic Accounts quality.
            <br />
            <ul>
              <li>
                {" "}
                <strong>Donations by Indian Citizens:</strong> <br />
                <ol>
                  <li>
                    {" "}
                    Sri Sharadamba Education And Rural Development Society®
                    S.B.A/c No.7072500103590501 Bank: Karnataka Bank, C.P.Bazar,
                    Sirsi - 581401 IFSC: KARB0000707
                  </li>
                  <li>
                    Sri Sharadamba Education And Rural Development Society®
                    Current A/c No.0520201001168 Bank: Canara Bank, C.P.Bazar,
                    Sirsi - 581401 IFSC: CNRB0000520
                  </li>
                </ol>
              </li>
              <li>
                {" "}
                <strong> Donations by Foreigners:</strong>
                <ol>
                  {" "}
                  <br />
                  <li>
                    Sri Sharadamba Education And Rural Development Society®
                    S.B.A/c No.40124772560 Bank: State Bank of India, New Delhi
                    Main Branch, FCRA Cell, Parliament Street, New Delhi-110001
                    IFSC: SBIN0000691 SWIFT Code: SBININBB104{" "}
                  </li>
                </ol>
              </li>
            </ul>
          </p>
        </div>
        <div
          className="cardStyle"
          data-aos="fade-down"
          style={{ borderRadius: "30px" }}
        >
          <h2
            id="title"
            className="paragraph"
            style={{ marginBottom: "40px", marginTop: "10px" }}
          >
            Payment gateway
          </h2>
          <div class="iframeDiv " data-aos="fade-down" style={{}}>
            <iframe
              class=""
              src="https://seard-donate.netlify.app"
              name="iframe_a"
              width={1000}
              height={1000}
            ></iframe>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default BankDetails;
