import React from "react";
import "../../App.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";

function Management() {
  let items = [
    {
      name: "Sri Vighneshwar N.Hegde",
      path: "assets/img/1.jpg",
      addr: "Bommanalli, Sirsi",
      pos: "President",
    },
    {
      name: "Sri Narayana Hegde",
      path: "assets/img/2.jpg",
      addr: "Gadikai",
      pos: "Vice-President",
    },
    {
      name: "Sri Mahabaleshwar V.Hegde",
      path: "assets/img/3.jpg",
      addr: "Hulgol, Sirsi",
      pos: "Secretary",
    },
    {
      name: "Sri Anant Bhat",
      path: "assets/img/4.jpg",
      addr: "Hulgol",
      pos: "Treasurer",
    },
    {
      name: "Sri Sadanand R Bhat",
      path: "assets/img/5.jpg",
      addr: "Nidgod",
      pos: "Member",
    },
    {
      name: "Sri Narayana G Hegde",
      path: "assets/img/6.jpg",
      addr: "Keshinmane",
      pos: "Member",
    },
    {
      name: "Sri Satish P Dev",
      path: "assets/img/7.jpg",
      addr: "Belale",
      pos: "Member",
    },
    {
      name: "Sri Ramachandra S Hegde",
      path: "assets/img/8.jpg",
      addr: "Devarakeri",
      pos: "Member",
    },
    {
      name: "Sri Pradeep K Hegde",
      path: "assets/img/9.jpg",
      addr: "Kalisara",
      pos: "Member",
    },
    {
      name: "Sri Kiran R Bhat",
      path: "assets/img/10.jpg",
      addr: "Bhairumbe",
      pos: "Member",
    },
    {
      name: "Smt. Bharati V Hegde",
      path: "assets/img/11.jpg",
      addr: "Kumbrigadde",
      pos: "Member",
    },
  ];
  const list = items.map(function (item, id) {
    return (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div
          data-aos="fade-down"
          class="member"
          style={{ padding: "10px", width: 320, height: 320, borderRadius: 15 }}
        >
          <img alt="" src={item.path} class="circle_avatar"></img>
          <div class="member-content">
            <h4 style={{ fontFamily: "Open Sans" }}>{item.name}</h4>
            <span>{item.addr}</span>
            <h5>{item.pos}</h5>
          </div>
        </div>
      </div>
    );
  });
  return (
    <body class="center">
      <Header />
      <div
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Management
        </h1>
      </div>
      <main
        id="main"
        data-aos="fade-in"
        style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "10px" }}
      >
        <section class="people" style={{ borderRadius: "20px" }}>
          <div class="container" data-aos="fade-up">
            <div class="row" data-aos="zoom-out" data-aos-delay="120">
              {list}
            </div>
          </div>
        </section>
      </main>
      <Fab />
      <Footer />
    </body>
  );
}

export default Management;
