import React from "react";
import Fab from "../../components/fab";
import Footer from "../../components/footer";
import Header from "../../components/header";
//todo make it dynamic using firebase
const Donors = () => {
  let items = [
    {
      path: "assets/img/profile.png",
      name: `His Holiness Jagadguru Shankaracharya
Sri Sri Bharati Teertha Mahaswamigal of Sri
Dakshinamnaya Sharada Peetham,
Shringeri`,
    },
    {
      path: "assets/img/profile.png",
      name: `His Holiness Sri Sri Gangadharendra
Saraswati Swamigal of Sri Sonda
Swarnavalli Mahasansthanam`,
    },
    {
      path: "assets/img/profile.png",
      name: `His Holiness Sri Sri Sushmeendra Teertha
Shripadangalavar, Sri Raghavendra Swami
Muth, Mantralaya`,
    },
    {
      path: "assets/img/profile.png",
      name: `His Holiness Sri Sri Nrisimhananda
Saraswati Swamigal, Kalasi`,
    },
  ];

  let devineBlessings = [
    {
      path: "assets/img/profile.png",
      name: `Shri Kshetra Dharmasthala,
Dakshina Kannada`,
    },
    {
      path: "assets/img/profile.png",
      name: `Matobar Shri Mahaganapti Devasthan
(Temple), Idagunji, Honnavar Talukam`,
    },
    {
      path: "assets/img/profile.png",
      name: `Shri Mahaganapati Devasthan (Temple),
Sirsi`,
    },
    {
      path: "assets/img/profile.png",
      name: `Shri Marikamba Devasthan (Temple), Sirsi`,
    },
  ];

  const list = items.map(function (item, id) {
    return (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div
          data-aos="fade-down"
          class="member"
          style={{
            padding: "10px",
            width: "540px",
            height: "540px",
            borderRadius: 15,
          }}
        >
          <img
            alt=""
            src={item.path}
            class="circle_avatar"
            style={{
              verticalAalign: "middle",
              width: "320px",
              height: "320px",
              borderRradius: "20%",

              boxShadow: "0 5px 13px rgb(0 0 0 / 0.2)",
              backdropFilter: "blur(12.5px)",
            }}
          ></img>
          <div class="member-content">
            <h4 style={{ fontFamily: "Open Sans" }}>{item.name}</h4>
            <span>{item.addr}</span>
            <h5>{item.pos}</h5>
          </div>
        </div>
      </div>
    );
  });

  const renderDevineBlss = devineBlessings.map(function (item, id) {
    return (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div
          data-aos="fade-down"
          class="member"
          style={{
            padding: "10px",
            width: "540px",
            height: "540px",
            borderRadius: 15,
          }}
        >
          <img
            alt=""
            src={item.path}
            class="circle_avatar"
            style={{
              verticalAalign: "middle",
              width: "320px",
              height: "320px",
              borderRradius: "20%",

              boxShadow: "0 5px 13px rgb(0 0 0 / 0.2)",
              backdropFilter: "blur(12.5px)",
            }}
          ></img>
          <div class="member-content">
            <h4 style={{ fontFamily: "Open Sans" }}>{item.name}</h4>
            <span>{item.addr}</span>
            <h5>{item.pos}</h5>
          </div>
        </div>
      </div>
    );
  });
  return (
    <body style={{ marginTop: "" }}>
      {" "}
      <Header />{" "}
      <div
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Guru's Grace
        </h1>
      </div>
      <section class="people cardStyle" style={{ borderRadius: "20px" }}>
        <div class="container" data-aos="fade-up">
          <div class="row" data-aos="zoom-out" data-aos-delay="120">
            {list}
          </div>
        </div>
      </section>
      {""}
      <div
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Divine Blessings:
        </h1>
      </div>
      <section class="people cardStyle" style={{ borderRadius: "20px" }}>
        <div class="container" data-aos="fade-up">
          <div class="row" data-aos="zoom-out" data-aos-delay="120">
            {renderDevineBlss}
          </div>
        </div>
      </section>
      <section class=" " style={{ borderRadius: "20px" }}>
        <div
          data-aos="fade-down"
          class="cardStyle"
          style={{ paddingBottom: 200, marginTop: "1px" }}
        >
          <h2
            id="title"
            style={{
              marginBottom: "40px",
              marginTop: "40px",
              textAlign: "center",
              color: "#3ee771",
            }}
          >
            Corporate donations
          </h2>
          <p className="paragraph" style={{ textAlign: "justify" }}>
            {" "}
            <ul
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontStyle: "normal",
                color: "black",
              }}
            >
              <li>
                LIC (Life Insurance Corporation of India) - Golden Jubilee
                Foundation Mumbai
              </li>
              <li>State Bank of India</li>
              <li>Apex Bank</li>
            </ul>
          </p>
        </div>
      </section>
      {""}
      <section class=" " style={{ borderRadius: "20px" }}>
        <div
          data-aos="fade-down"
          class="cardStyle"
          style={{ paddingBottom: 200, marginTop: "1px" }}
        >
          <h2
            id="title"
            style={{
              marginBottom: "40px",
              marginTop: "40px",
              textAlign: "center",
              color: "#3ee771",
            }}
          >
            Funds of Foundations &amp; Trusts:
          </h2>
          <p className="paragraph" style={{ textAlign: "justify" }}>
            {" "}
            <ul
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontStyle: "normal",
                color: "black",
              }}
            >
              <li>
                Mohanlal Chandravati Jain Charitable Trust., Mumbai Foundation
              </li>
              <li>
                Sitaram Jindal Trust &amp; Manav Jagruti Foundation, Bangalore
              </li>
              <li>BLB Trust, New Delhi</li>
              <li>Yashodhara Oberoi Foundation, Mumbai</li>
            </ul>
          </p>
        </div>
      </section>
      {""}
      <section class=" " style={{ borderRadius: "20px" }}>
        <div
          data-aos="fade-down"
          class="cardStyle"
          style={{ paddingBottom: 200, marginTop: "1px" }}
        >
          <h2
            id="title"
            style={{
              marginBottom: "40px",
              marginTop: "40px",
              textAlign: "center",
              color: "#3ee771",
            }}
          >
            Cooperative contribution
          </h2>
          <p className="paragraph" style={{ textAlign: "justify" }}>
            {" "}
            <ul
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontStyle: "normal",
                color: "black",
              }}
            >
              <li>Taluka Agriculture Primary Cooperative Marketing Society</li>
              <li>Totgars Sales Society Ltd. Sirsi</li>
              <li>
                Hulgol Primary Agricultural Service Cooperative Society ®,
                Bhairumbe,
              </li>
              <li>Totgars Rural Credit Cooperative Bank Ltd. Sirsi</li>
            </ul>
          </p>
        </div>
      </section>
      {""}{" "}
      <section class=" " style={{ borderRadius: "20px" }}>
        <div
          data-aos="fade-down"
          class="cardStyle"
          style={{ paddingBottom: 200, marginTop: "1px" }}
        >
          <h2
            id="title"
            style={{
              marginBottom: "40px",
              marginTop: "40px",
              textAlign: "center",
              color: "#3ee771",
            }}
          >
            M.P.- M.L.A. Local Area Development Funds
          </h2>
          <p className="paragraph" style={{ textAlign: "justify" }}>
            {" "}
            <ul
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontStyle: "normal",
                color: "black",
              }}
            >
              <li>Sri Anantkumar Hegde, Sirsi, M.P.</li>
              <li>Smt.Margaret Alva, Bangalore, M.P.</li>
              <li>Justice M.Rama Jois Bangalore, M.P.</li>
              <li>Sri Basavaraj Horatti, Hubli, MLC</li>
              <li>Prof.M.R.Doreswami, Bangalore, MLC</li>
              <li>Dr.S.R.Leela, Bangalore, MLC</li>
              <li>Sri Vijay Sankeshwar, Hubli, MLC</li>
            </ul>
          </p>
        </div>
      </section>
      <Fab />
      <Footer />
    </body>
  );
};

export default Donors;
