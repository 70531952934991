import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
const Endowments = () => {
  var data = [
    {
      p: "Best Student (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Brilliant Student (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Actor (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Dancer (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Classical Vocalist (Boy and Girl) Rs.500/=x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Classical Instrumentalist (Boy and Girl)Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Artist (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Athlete (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Best Player (Boy and Girl) Rs.500/= x 2",
      amt1: "Rs.1000/=",
      amt2: "Rs.20,000/=",
    },
    {
      p: "Topper in a Subject Rs.500/= x 6",
      amt1: "Rs.3000/=",
      amt2: "Rs.60,000/=",
    },
    {
      p: "Toppers in Competitions Rs.500/= x 20",
      amt1: "Rs.10000/=",
      amt2: "Rs.200000/=",
    },
  ];
  let i = 1;
  var renderData = data.map((e) => {
    return (
      <tr>
        <td style={{ padding: 10 }}>{e.p}</td>
        <td style={{ padding: 10 }}>{e.amt1} </td>
        <td style={{ padding: 10 }}>{e.amt2} </td>
      </tr>
    );
  });
  return (
    <div>
      <Header />
      <div
        data-aos="fade-down"
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Endowments
        </h1>
      </div>
      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ padding: 25, marginTop: "10px" }}
      >
        {""}

        <table
          className=""
          border="2px"
          width="100%"
          style={{ padding: "20px" }}
        >
          {renderData}
        </table>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default Endowments;
