import React from "react";
import Header from "../../components/header.js";

const Hostel = () => {
  return (
    <div>
      <Header />
      <h1 class="text-center" style={{ marginTop: "40%" }}>
        Hostel page coming soon
      </h1>
    </div>
  );
};

export default Hostel;
