import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header.js";
import Fab from "../../components/fab";
import "../../App.css";
class Introduction extends React.Component {
  render() {
    return (
      <body>
        <Header />
        <section>
          <div
            class="card text-center"
            style={{
              // backgroundImage: "linear-gradient(springgreen,cyan,springgreen)",
              color: "#311054",
            }}
          >
            <div
              data-aos="fade-down"
              class="text-center cardStyle"
              style={{ marginTop: "100px", background: "#3ee771" }}
            >
              <h1
                id="title"
                data-aos="slide-down"
                style={{ padding: "32px", fontSize: "46px", color: "white" }}
              >
                SEARD SOCIETY ®
              </h1>
              <p
                style={{
                  fontSize: "2.1vw",
                  textAlign: "center",
                  color: "#032D3C",
                }}
              >
                SRI SHARADAMBA EDUCATION & RURAL DEVELOPMENT SOCIETY ®
              </p>
            </div>
            <div
              data-aos="fade-down"
              class="cardStyle"
              style={{
                textAlign: "start",
                marginTop: "100px",
                backgroundColor: "white",
                borderRadius: "20px",
              }}
            >
              {" "}
              <h3
                id="title"
                style={{ textAlign: "center", color: "springgreen" }}
              >
                OUR VISION
              </h3>
              <p
                style={{ textAlign: "center", marginTop: "10px" }}
                class="paragraph"
              >
                Quality-Education, Clean Environment and Gainful Employment to
                Rural People in their Native Place, without Migration
              </p>
              <ul style={{ marginLeft: "20px", marginRight: "20px" }}>
                <li>
                  Education should not become a commercial commodity, but ever
                  be a service to train and mould children to make them best
                  entrepreneurs, engineers, scientists, artists, artisans,
                  agriculturists, etc, above all good citizens and human beings.
                </li>
                <li>
                  The survival of mankind and living beings depends upon
                  judicious use of our natural resources and minimization of
                  abuse of our environment. Creation of awareness and
                  involvement in conservation activities, especially among
                  children, would surely pave way for a huge difference.
                </li>
                <li>
                  Support to establish new services and industries would
                  increase employment opportunities in the rural region.
                  Dissemination of information about various government schemes,
                  guidance to apply for them, leverage in getting necessary
                  finance, will certainly kindle the entrepreneurship in rural
                  youth, especially, women.
                </li>
              </ul>
            </div>
            <h1
              data-aos="fade-down"
              id="title"
              style={{
                marginBottom: "40px",

                color: "springgreen",
              }}
            >
              Introduction
            </h1>
            <div
              data-aos="fade-down"
              class="cardStyle"
              style={{
                paddingBottom: 25,
                backgroundColor: "white",
                borderRadius: "20px",
              }}
            >
              <p
                className="paragraph"
                style={{
                  textAlign: "justify",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                Shri Sharadamba High-School was started by the elders of this
                region during 1957. Many of the founders of this school were
                illiterates and poor. Among the founders one was a child-widow
                and another person had no children at all. They built this
                institution despite their educational, financial and
                infrastructural constraints. Many homes had become hostels for
                outside teachers and students. Majority of them contributed
                generously, though their resources were limited. Due to their
                foresight and dream, not only children of the surrounding
                villages, but many from various parts of this district were able
                to get secondary education. <br></br>
                <strong>Sri Battevinayaka Temple</strong>
                <br />
                The school was run in the premises of this temple under a
                thatched roof for almost 6 years initially.
              </p>{" "}
            </div>{" "}
            <img
              data-aos="fade-down"
              src="./assets/img/vinayaka.png"
              style={{ scale: ".8" }}
            ></img>
            <div
              data-aos="fade-down"
              class="cardStyle"
              style={{
                paddingBottom: 5,
                backgroundColor: "white",
                borderRadius: "20px",
              }}
            >
              <h2
                id="title"
                style={{
                  marginBottom: "40px",
                  marginTop: "10px",
                  color: "springgreen",
                }}
              >
                Infrastructure
              </h2>
              <p className="paragraph" style={{ textAlign: "justify" }}>
                {" "}
                The society has 1.6 Hectare area of land on the hilly top with
                beautiful panoramic view. The school has a Golden Jubilee Block
                having 6 classrooms, office-cum-staff-room, science lab and
                computer lab, old school building with a small indoor
                auditorium, 7 class-rooms (used as Koushalya training centre,
                library and reading room) as well as an open-air theatre.
                Further, higher primary section has a separate building with
                three class rooms. The former laboratory in the old building is
                converted as boys’ hostel and one of the staff quarters is
                altered as girls’ hostel. There are four staff quarters. The
                school has sports ground with Volley-Ball & Kho-Kho fields.
                Coconut is grown in about 1-00-00 Acre and practical training is
                provided to the children regarding agricultural operations in
                the same. Botanical Garden (Sri Sharadamba Dhanvantari Vana) is
                being developed in 1.00 Hectare land adjacent to the school.{" "}
              </p>
            </div>
          </div>
        </section>
        <Fab />
        <Footer />
      </body>
    );
  }
}

export default Introduction;
