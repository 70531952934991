import React from "react";
import Header from "../../components/header.js";
import Footer from "../../components/footer.js";
import Fab from "../../components/fab.js";

const Projects = () => {
  var data = [
    { p: "Construction of Girls’ Hostel", amt: "2,00,00,000.00" },
    { p: "Construction of Boys’ Hostel", amt: "2,00,00,000.00" },
    {
      p: "Construction of Classrooms, Halls and Toilets",
      amt: "6,00,00,000.00",
    },
    {
      p: "Semi-closed Auditorium with Sound and Light System",
      amt: "1,00,00,000.00",
    },
    {
      p: "Development of Laboratory and Science Park",
      amt: "1,00,00,000.00",
    },
    {
      p: "Sports and Games Infrastructure ",
      amt: "50,00,000.00",
    },
    { p: "Development of Library", amt: "50,00,000.00" },
    {
      p: "Transport Vehicles",
      amt: "70,00,000.00",
    },
    {
      p: "Water Harvesting Tank for Supply of Drinking Water ",
      amt: "15,00,000.00",
    },
    {
      p: "Furniture and Fixtures",
      amt: "15,00,000.00",
    },
  ];
  let i = 1;
  var renderData = data.map((e) => {
    return (
      <tr>
        <td>{i++}</td>
        <td>{e.p} </td>
        <td style={{ textAlign: "center" }}>{e.amt}</td>
      </tr>
    );
  });
  return (
    <div>
      <Header />
      <section>
        <div
          class="card text-center"
          style={{
            // backgroundImage: "linear-gradient(springgreen,cyan,springgreen)",
            color: "#311054",
          }}
        >
          <div
            data-aos="fade-down"
            class="text-center cardStyle"
            style={{ marginTop: "100px", background: "#3ee771" }}
          >
            <h1
              id="title"
              data-aos="slide-down"
              style={{ padding: "32px", fontSize: "46px", color: "white" }}
            >
              Future projects and estimates
            </h1>
          </div>

          <img
            data-aos="fade-down"
            src="/assets/img/projects/hostel1.jpg"
            class="cardStyle"
            alt="school"
          ></img>
          <img
            data-aos="fade-down"
            src="/assets/img/projects/hostel2.jpg"
            class="cardStyle"
            alt="school"
          ></img>
          <table>
            <tr>
              <th>SI</th>
              <th>Particulars</th>
              <th style={{ textAlign: "center" }}>Amount (in INR)</th>
            </tr>
            {renderData}
            <tr>
              <th></th>
              <th>Total</th>
              <td style={{ textAlign: "center", fontWeight: "bold" }}>
                14,00,00,000.00
              </td>
            </tr>
          </table>
        </div>
      </section>
      <Fab />
      <Footer />
    </div>
  );
};

export default Projects;
