import React from "react";
import Header from "../../components/header.js";
import Fab from "../../components/fab.js";
import Footer from "../../components/footer.js";
const Academics = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Academics
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          Traditionally, education meant teaching language and arithmetic. But,
          the present aim is to manifest the innate talent in students.
          Realising the role of education, various innovative schemes and
          programmes have been introduced in this school also. Sri Sharadamba
          High-School (Kannada Medium) was started 3 years before registration
          of this society, in 1957 itself. As education in English medium gives
          an edge over others in the competitive world, and to stop child-drain
          from rural regions to urban centres in pursuit of English medium, Sri
          Sharadamba English Medium High School was started in 2007. The schools
          have got good reputation in this region regarding their educational
          standards. The performance in the matriculation is more than 90% (in
          the Kannada Medium) during most of the years and continuously 100% in
          the English Medium since its inception. Unlike many schools where only
          talented students are admitted to show excellent performance, here
          excellent results are shown despite mass admission.
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default Academics;
