import "../App.css";

const Fab = () => {
  return (
    <div style={{ position: "fixed", bottom: "50px", right: "38px" }}>
      <a href="#bank">
        {" "}
        <button className="fab">
          <strong>Donate</strong>{" "}
        </button>
      </a>
    </div>
  );
};

export default Fab;
