import React from "react";

import "../App.css";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "" }}>
      <main id="main">
        <div class="container" style={{ fontStyle: "normal" }}>
          <div class="row" style={{ textAlign: "center", marginTop: "50px" }}>
            <div class="col-sm">
              <p class="small text-muted mb-0">
                SRI SHARADAMBA EDUCATION & RURAL DEVELOPMENT SOCIETY ®
              </p>

              <p class="small text-muted mb-0">&copy; Phone:08384-279392</p>
              <p class="small text-muted mb-0">
                BHAIRUMBE, SIRSI, UTTARA KANNADA KARNATAKA, INDIA– 581402.
              </p>
            </div>
            <div class="col-sm" style={{ textAlign: "center" }}>
              <ul class="list-unstyled text-muted-center">
                <a
                  href="https://www.youtube.com/watch?v=JZUQgY35IKE"
                  target="blank"
                >
                  <img
                    src="/assets/img/ytb.png"
                    style={{ margin: "10px", width: "32px" }}
                  ></img>{" "}
                  Youtube
                </a>
                <br></br>
                <a href="#">
                  <img
                    src="/assets/img/inst.png"
                    style={{ margin: "10px", width: "32px" }}
                  ></img>{" "}
                  Instagram
                </a>
                <a href="#">
                  <img
                    src="/assets/img/fb.png"
                    style={{ margin: "10px", width: "32px" }}
                  ></img>{" "}
                  Facebook
                </a>
              </ul>
            </div>
            <div class="col-sm" style={{ textAlign: "center" }}>
              <p class="small text-muted">Phone : 08384-279392</p>
              <p class="small text-muted">Mobile : +91-9448425155</p>
              <p class="small text-muted">
                Email : seardsociety@gmail.com/info.ssh@gmail.com
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Footer;
