import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
const RegistrationDetails = () => {
  var data = [
    { c1: "Registration Number", c2: "S-2502/60-61" },
    { c1: "Place of Registration", c2: "KARWAR" },
    { c1: "Date of Registration	", c2: "13th July, 1960" },
    {
      c1: "Registered Act",
      c2: "The Karnataka Societies Registration Act, 1860.",
    },
    {
      c1: "Income Tax Recognition",
      c2: `Recognition u/s 12(A): AAGTS7885PE20214
From AY 2022-23 to AY 2026-27
`,
    },
    {
      c1: "",
      c2: `Recognition u/s 80(G): AAGTS7885PF20214
From AY 2022-23 to AY 2026-27
`,
    },
    { c1: "", c2: "PAN : AAGTS7885P" },
    { c1: "", c2: "TAN : BLRS6893*C" },

    {
      c1: "Recognition under FCRA",
      c2: "No.094600069 for Education & Social (Valid for 5 years from 01-01-2022) ",
    },
    { c1: "Niti Ayog’s Darpan Unique Id", c2: "KA/2010/0036124 " },
    { c1: "Registration Under CSR-I", c2: "CSR00009908" },
    {
      c1: "DIES Code: Sri Sharadamba English Medium High School Bhairumbe ",
      c2: "29340303604 ",
    },
  ];
  let i = 1;
  var renderData = data.map((e) => {
    return (
      <tr>
        <th style={{ padding: 10 }}>{e.c1}</th>
        <td style={{ padding: 10 }}>{e.c2} </td>
      </tr>
    );
  });
  return (
    <div>
      <Header />
      <div
        data-aos="fade-down"
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Registration details of the society
        </h1>
      </div>
      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ padding: 25, marginTop: "10px" }}
      >
        {""}

        <table
          className=""
          border="2px"
          width="100%"
          style={{ padding: "20px" }}
        >
          <tr>
            <th style={{ paddingLeft: "10px" }}>
              Name & Address of the Institution / Society{" "}
            </th>
            <th style={{ paddingLeft: "10px" }}>
              SHRI SHARADAMBA EDUCATION & RURAL DEVELOPMENT SOCIETY ® BHAIRUMBE,
              SIRSI, UTTARA KANNADA – 581402
            </th>
          </tr>
          <tr>
            <th style={{ paddingLeft: "10px" }} colSpan={2}>
              Registration Details
            </th>
          </tr>
          {renderData}
        </table>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default RegistrationDetails;
