import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
const Annapoorna = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Annapoorna (Mid-Day Meal){" "}
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          As this school is situated in a rural area, where small hamlets are
          situated in distant places amidst forests, hills and valleys, many
          students have to walk 4-5 KMs to come to school. They cannot return to
          their homes during the lunch break time. Many of them cannot afford to
          have food in hotels. So, quite long before the introduction of mid-day
          meal (Akshara Dasoha) scheme by the Government of Karnataka, mid-day
          meal was started at the school to all the students at a nominal rate
          of Rs.4/=. By this, students were not only done away with rushing to
          their homes for lunch, but also were engaged in useful activities
          after lunch hours, in the school premises. Today, steam cooking range
          has been installed for preparation of fresh hygienic food and
          necessary FSSAI Registration has also been availed.{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default Annapoorna;
