// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAi_XxIfyH5x6z_zf_DsvL6XUCHoMVXsr0",
  authDomain: "seardadmin.firebaseapp.com",
  projectId: "seardadmin",
  storageBucket: "seardadmin.appspot.com",
  messagingSenderId: "64869371717",
  appId: "1:64869371717:web:e6ab3688109d217ed2f82c",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
