import "../../App.css";
import db from "../../firebase.config";
import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
import { useGetData } from "./../../methods/get_data.js";
const Gallery = () => {
  const [galleryData] = useGetData("gallery");
  let renderData = galleryData.map((ele, i) => {
    return (
      <div class="col-md-6 d-flex align-items-stretch">
        <a href={ele.path}>
          {" "}
          <div class="card">
            <div class="card-img " style={{ overflow: "hidden" }}>
              <img
                id="image"
                src={ele.path}
                alt=""
                style={{
                  objectFit: "cover",
                }}
              />
              <p style={{ textAlign: "center" }}>{ele.title}</p>
            </div>
          </div>
        </a>
      </div>
    );
  });
  return (
    <main id="main">
      <Header />
      <div
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Gallery
        </h1>
      </div>
      <section>
        <div class="container" data-aos="slide-up">
          <div class="row">{renderData}</div>
        </div>
      </section>
      <Fab />
      <Footer />
    </main>
  );
};

export default Gallery;
