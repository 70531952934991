import "../../App.css";

import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
import { useGetData } from "../../methods/get_data";
const News = () => {
  const [newsEvents] = useGetData("news");

  let renderData = newsEvents.map((ele) => {
    return (
      <div class="col-md-6 d-flex align-items-stretch">
        <a href={ele.path}>
          {" "}
          <div class="card">
            <div class="card-img ">
              <img
                id="image"
                src={ele.path}
                alt=""
                style={{ width: "500px", height: "500px", objectFit: "cover" }}
              />
            </div>
            <div
              class="card-body"
              style={{
                background: "white",
                boxShadow: "0 8px 32px 0 rgba(36, 44, 160, 0.37)",
                backdropFilter: "blur( 12.5px )",
                borderRadius: "10px",
                border: "1px solid rgba( 255, 255, 255, 0.18 )",
                fontFamily: "Lexend Deca",
              }}
            >
              <h4 class="card-title">{ele.title} </h4>
              <p class="fst-italic text-center">{ele.date}</p>
              <p class="card-text">{ele.description}</p>
            </div>
          </div>
        </a>
      </div>
    );
  });
  return (
    <main id="main">
      <Header />
      <div
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          News
        </h1>
      </div>
      <section>
        <div class="container" data-aos="slide-up">
          <div class="row">{renderData}</div>
        </div>
      </section>
      <Fab />
      <Footer />
    </main>
  );
};

export default News;
