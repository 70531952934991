import React from "react";
import Fab from "../../components/fab";
import Footer from "../../components/footer";
import Header from "../../components/header";

const SportsAndGames = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Sports and Games
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          Sports &amp; Games, including physical exercises, play a positive role
          in the physical as well as psychological improvement of a child.
          Sincere involvement will surely result in good recreation and health.
          The school has developed sports grounds. Training is imparted in
          physical education as well as games like Volley-Ball, Kabaddi,
          Kho-Kho, Badminton, etc. Further, Yoga &amp; Pranayama, which are
          found to be very effective in improving individual’s physical &amp;
          mental health, have also been introduced. It is a matter of pride that
          many students have participated in the sports meet at the national
          level in the Badminton and Yoga. Many students have become acclaimed
          Yoga Teachers..{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default SportsAndGames;
