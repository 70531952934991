import React from "react";
import Header from "../components/header";
import Body from "../components/body.js";
import Footer from "../components/footer.js";
import "../App.css";
import Fab from "../components/fab";
function Home() {
  return (
    <>
      <Header />
      <Body />
      <Fab />
      <Footer />
    </>
  );
}

export default Home;
