import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
const RecurringExp = () => {
  var data = [
    { p: "A Set of School Uniform", amt: "Rs.750/=" },
    { p: "A School Bag", amt: "Rs.400/=" },
    {
      p: "Complete Set of Books for a Child",
      amt: "Rs.500/=",
    },
    {
      p: "Sponsoring the Educational Expenses of a Child per annum",
      amt: "Rs.12,000/=",
    },
    {
      p: "Sponsoring the monthly Hostel Expenses of a poor student",
      amt: "Rs.2,600/=",
    },
    {
      p: "Sponsoring lunch under Annapoorna Scheme on any special occasion like Birth Anniversary, Marriage Anniversary, Death Anniversary of your family members (Cost/Child/Meal would be approximately Rs.20/= and total Number of Children 350)",
      amt: "Rs.7,000/=",
    },
    {
      p: "Sponsoring Personality Development Training Camp",
      amt: "Rs.15000/=",
    },
    {
      p: "Sponsoring 2 months Research Expenses of 5 children at Bangalore",
      amt: "Rs.75,000/=",
    },
  ];
  let i = 1;
  var renderData = data.map((e) => {
    return (
      <tr>
        <td style={{ padding: 10 }}>{i++}</td>
        <td style={{ padding: 10 }}>{e.p} </td>
        <td style={{ textAlign: "center" }}>{e.amt}</td>
      </tr>
    );
  });
  return (
    <div style={{ backgroundColor: "" }}>
      <Header />
      <div
        data-aos="fade-down"
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Recurring costs
        </h1>
      </div>
      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{
          padding: 25,
          marginTop: "10px",
          marginBottom: "100px",
          backgroundColor: "white",
        }}
      >
        <table
          className=""
          border="2px"
          width="100%"
          style={{ padding: "20px" }}
        >
          {renderData}
        </table>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default RecurringExp;
