import React from "react";
import "../../App.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";
import {
  useGetEnFacultyData,
  useGetKnFacultyData,
} from "../../methods/get_data";
const Faculty = () => {
  const [enfacultyList] = useGetEnFacultyData();
  const [knfacultyList] = useGetKnFacultyData();
  const enlist = enfacultyList.map(function (item, id) {
    return (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div
          data-aos="fade-down"
          class="member"
          style={{
            padding: "10px",
            width: 320,
            height: 320,
            borderRadius: 15,
            marginBottom: "10px",
          }}
        >
          <img alt="" src={item.path} class="circle_avatar"></img>
          <div class="member-content">
            <h4 style={{ fontFamily: "Open Sans" }}>{item.name}</h4>
            <span>{item.addr}</span>
            <h5>{item.pos}</h5>
            <span style={{ textStyle: "italic" }}>({item.designation})</span>
          </div>
        </div>
      </div>
    );
  });
  const knlist = knfacultyList.map(function (item, id) {
    return (
      <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
        <div
          data-aos="fade-down"
          class="member"
          style={{
            padding: "10px",
            width: 320,
            height: 320,
            borderRadius: 15,
            marginBottom: "10px",
          }}
        >
          <img alt="" src={item.path} class="circle_avatar"></img>
          <div class="member-content">
            <h4 style={{ fontFamily: "Open Sans" }}>{item.name}</h4>
            <span>{item.addr}</span>
            <h5>{item.pos}</h5>
            <span style={{ textStyle: "italic" }}>({item.designation})</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <Header />
      <div
        class="text-center cardStyle"
        style={{ marginTop: "100px", background: "#3ee771" }}
      >
        <h1
          id="title"
          data-aos="slide-down"
          style={{ padding: "32px", fontSize: "46px", color: "white" }}
        >
          Teaching Staff Details
        </h1>
      </div>
      <main
        id="main"
        data-aos="fade-in"
        style={{
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
          }}
        >
          English Medium teaching staff
        </h2>
        <section class="people" style={{ borderRadius: "20px" }}>
          <div class="container" data-aos="fade-up">
            <div class="row" data-aos="zoom-out" data-aos-delay="120">
              {enlist}
            </div>
          </div>
        </section>
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
          }}
        >
          Kannada Medium teaching staff
        </h2>
        <section class="people" style={{ borderRadius: "20px" }}>
          <div class="container" data-aos="fade-up">
            <div class="row" data-aos="zoom-out" data-aos-delay="120">
              {knlist}
            </div>
          </div>
        </section>
      </main>
      <Fab />
      <Footer />
    </div>
  );
};

export default Faculty;
