import React from "react";
import Header from "../../components/header.js";
import Footer from "../../components/footer.js";

const Cultural = () => {
  return (
    <div>
      <Header />
      <div className="container" style={{ height: "1000px" }}>
        <h1 style={{ margin: "200px", textAlign: "center" }}>Coming soon</h1>
      </div>
      <Footer />
    </div>
  );
};

export default Cultural;
