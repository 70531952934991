import React from "react";
import Fab from "../../components/fab";
import Footer from "../../components/footer";
import Header from "../../components/header";

const Appeal = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          An Appeal
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          The world is changing with a rapid pace. To cope with the fast At
          present, the construction of Girls’ Hostel is under progress and
          renovation of the existing school building which is altered as Boys’
          Hostel is also under progress. The estimated cost is around{" "}
          <strong> Rs.300 lakhs.</strong> The school is indebted to its
          founders, donors, philanthropists, foundations, institutions, Members
          of Parliament and Members of Legislative Council, who have contributed
          generously to build this institution. Their services have been
          fundamental in the growth of this school. Now, we earnestly request
          all the philanthropists, funding agencies and alumni to extend their
          whole-hearted support to develop this institution into a model rural
          education centre. We earnestly appeal to you kindly to donate
          generously to develop this organisation to help the rural children who
          are deprived of all the urban facilities and opportunities.{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default Appeal;
