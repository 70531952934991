import React from "react";
import Fab from "../../components/fab";
import Footer from "../../components/footer";
import Header from "../../components/header";

const CulturalActivities = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Cultural Activities
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          While cultural activities, like dance &amp; drama, drawing &amp;
          painting, music, etc. are a means to express our emotions and feelings
          in a highly stylised and symbolic manner, they are fast becoming
          highly remunerative professional careers as well. The ever growing
          entertainment industry is demanding more and more talented artists.
          So, students who are gifted with special talent in any of the above
          cultural forms may pursue it to achieve self satisfaction as well as
          to make it their source of livelihood. “Nada-kala Sangeeta Vidyalaya”
          the music wing of the association provides special training to the
          interested students in Hindustani Classical Vocal and Tabla. Training
          in drawing and painting is also imparted to the children. Yakshagana,
          a unique colourful dance-drama of Karnataka, which brings the Gods and
          Demons as well as epic heroes to life, is also being taught to
          children. Further, drama, debate, poetry recitation, speech &amp;
          essay competitions and other cultural programmes are held periodically
          to enable the students to exhibit their talents as well as to
          establish social harmony through community participation.{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default CulturalActivities;
