import { Navbar, Nav, NavDropdown, Container, Image } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "../App.css";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Header = () => {
  const { width, height } = getWindowDimensions();
  return (
    <div style={{}}>
      <header
        id="header"
        class="fixed-top"
        style={{ backgroundColor: "springgreen" }}
      >
        <Navbar
          collapseOnSelect
          expand="lg"
          style={{ backgroundColor: "springgreen" }}
        >
          <Container>
            <Navbar.Brand
              href=""
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              SEARD SOCIETY
            </Navbar.Brand>
            <Navbar.Toggle
              id="button"
              style={{ borderColor: "transparent" }}
              aria-controls="responsive-navbar-nav"
            />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#/">Home</Nav.Link>
                <NavDropdown
                  style={{ padding: "1px" }}
                  title="About Us"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item style={{ width: "" }} href="#introduction">
                    Introduction
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="#registration-details"
                    style={{ width: "200px" }}
                  >
                    Registration details
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <Link to="founders">Founders</Link>
                  <NavDropdown.Divider />
                  <Link to="management">Management</Link>
                  <NavDropdown.Divider />
                  <Link to="faculty">Faculty</Link>
                  <NavDropdown.Divider />
                  <Link to="donors">Past donors</Link>
                </NavDropdown>

                <NavDropdown
                  style={{ padding: "1px" }}
                  title="Curricular & extra curricular activities"
                >
                  <NavDropdown.Item href="#academics">
                    Academics
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#kaushalya">
                    Kaushalya
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#green-initiatives">
                    Green Initiatives
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#sports-games">
                    Sports and games
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#cultural-activity">
                    Cultural activities
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#value-edu">
                    Value educations
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="#annapoorna"
                    style={{ width: "250px" }}
                  >
                    Annapoorna (Mid-day Meal)
                  </NavDropdown.Item>{" "}
                </NavDropdown>
                <Link
                  style={{ padding: "1px", color: "rgba(0, 0, 0, 0.55)" }}
                  to="projects"
                >
                  Projects
                </Link>

                {/* gap */}
                <NavDropdown
                  style={{ padding: "1px" }}
                  title="Recurring Expenses"
                >
                  <NavDropdown.Item href="#recurring">
                    Recurring costs
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#endowments">
                    Endowments
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown style={{ padding: "1px" }} title="News and Events">
                  {""}
                  <NavDropdown.Item href="#upcoming">
                    Upcoming
                  </NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#news">News</NavDropdown.Item>{" "}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#gallery">
                    Gallery
                  </NavDropdown.Item>{" "}
                </NavDropdown>
                <NavDropdown style={{ padding: "1px" }} title="Donate">
                  <NavDropdown.Item href="#appeal">An appeal</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="#recog-donors"
                    style={{ width: "250px" }}
                  >
                    Recognition of donors
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#donors-list">
                    Donors list
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#bank">
                    Donation options
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
};

export default Header;
