
import "./App.css";
import Home from './pages/home';


function App() {
  return (
    <Home />
  );
}

export default App;


