import React from "react";
import Fab from "../../components/fab";
import Footer from "../../components/footer";
import Header from "../../components/header";

const ValueEducation = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Value Education
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          The world is changing with a rapid pace. To cope with the fast
          changing environment, to excel in life, to face the life’s challenges
          certain skills are very essential. The thrust must be on
          transformation rather than on mere information. Education which
          imparts, infuses &amp; inculcates human, social &amp; national values
          in individual life is required to make everyone responsible citizen.
          So moral and ethical values are inculcated by focussing on the lives
          of saints and patriots by introducing SGVEP{" "}
          <strong>
            (Swami Vivekananda’s Sustained Graded Value Education Programme)
          </strong>{" "}
          of Sri Ramakrishna Ashrama.{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default ValueEducation;
