import "../App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Body = () => {
  const { width, height } = getWindowDimensions();
  console.log(width);
  return (
    <div>
      <section
        id="home"
        class="d-flex justify-content-center align-items-center"
      >
        <div
          // class="container"
          // data-aos-delay="120"
          // data-aos="fade-up"
          style={{
            align: "bottom",
            position: "relative",
            marginTop: `${600}px`,
          }}
        >
          <h1
            id="heading"
            style={{
              fontSize: "2.4vw",
              textAlign: "left",
              padding: "10px",
              // fontWeight: `${width / 100}px`,
            }}
          >
            Welcome to SEARD SOCIETY
          </h1>

          {/* <Button id="bttn" href="#introduction" data-aos="flip-down">
            About us
          </Button> */}
        </div>
      </section>
    </div>
  );
};

export default Body;
