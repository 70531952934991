import React from "react";
import Fab from "../../components/fab";
import Footer from "../../components/footer";
import Header from "../../components/header";

const Kaushalya = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Koushalya – Integrated Vocational Life Skills Training Programme:
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          Koushalya is a hands-on, do it yourselves model, practical training to
          children in agriculture, apiculture &amp; animal husbandry, wiring,
          bar-bending, welding, fabrication, plumbing, cooking, tailoring, and
          the like. It is an effort to equip the children with skills to handle
          their day-to-day problems like leaking tap, missing button, burnt fuse
          wire, choked wash basin, failed switch, etc. It helps to develop a
          sense of self-reliance and respect for work. It also promotes
          entrepreneurship among children. It is an exposure to the ‘World of
          work’. This curriculum being conducted during the last one decade has
          received marvelous response from the children and prompted many to
          pursue professions of their interest.{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default Kaushalya;
