import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";

const Founders = () => {
  let data = [
    "Sri Gajanana Mahabaleshwar Hegde, Hulgol, Sirsi, Founder-Secretary",
    "Shri Shreepad Hegde Kadave",
    "Shri Mableshwar V Hegde Bhairumbe",
    "Shri Venkatraman M Hegde Hulgol",
    "Shri Shivaram Naras Bhat Nidgod",
    "Shri Lakshminarayana Venkappa Hegde Bugadimane",
    "Shri Venkatramana Narasappa Hegde Hulgol",
    "Shri Narasimha Ganapati Bhat Agasal",
    "Shri Subraya Ramayya Hegde Somanalli",
    "Shri Narayana Subraya Hegde Keshinmane",
    "Shri Subraya Ramakrishnna Hegde Ashisara",
    "Shri V P Joshi Karki",
    "Smt.Gange Kom Parameshwara Hegde Targod",
    "Shri Vighneshwar Govind Hegde Hulgol",
    "Shri Mahadev Sambayya Hegde Targod",
    "Shri Ganapati Venkappa Hegde Karekoppa",
    "Shri Gajanan Narayan Hegde Targod",
    "Shri Vishweshwar Venkatraman Bhat Hulgol",
    "Shri Anant Venkatramana Hegde Gummanamane",
    "Smt.Subbi Kom Shankar Bhat Nidgod",
    "Shri Shambhu Janardhana Bhat Agasal",
    "Shri Ramachandra Timmayya Hegde Devarakari",
    "Shri Narasimha Ramachandra Hegde Bommanalli",
  ];
  let i = 1;
  var renderData = data.map((e) => {
    return (
      <tr>
        <td textAlign="left">{i++}</td>
        <td>{e} </td>
      </tr>
    );
  });
  return (
    <div className="container-center">
      <div>
        <Header />
        <div
          data-aos="fade-down"
          class="text-center cardStyle"
          style={{ marginTop: "100px", background: "#3ee771" }}
        >
          <h1
            id="title"
            data-aos="slide-down"
            style={{ padding: "32px", fontSize: "46px", color: "white" }}
          >
            Founders
          </h1>
        </div>
        <div
          data-aos="fade-down"
          class="cardStyle"
          style={{ paddingBottom: 25, paddingTop: 25, marginTop: "10px" }}
        >
          <table
            className=""
            border="0px"
            width="100%"
            style={{ paddingLeft: "20px" }}
          >
            <tr>
              <th>SI</th>

              <th style={{ textAlign: "center" }}>Name and address</th>
            </tr>
            {renderData}
          </table>
        </div>
        <Fab />
        <Footer />
      </div>
    </div>
  );
};

export default Founders;
