import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Fab from "../../components/fab";

const DonorRecognition = () => {
  var data = [
    {
      type: `Complete Cost of
a Building or
Block`,

      perk: `The entire building or block would be named after the donor and their
photos will be displayed in the hall and published in the website, names will
be engraved on a special granite plaque and mementos will be presented
during Diamond Jubilee.`,
    },
    {
      type: `Rs.10.00 lakh and
Above`,

      perk: `Their photos will be displayed in the hall and published in the website,
names will be engraved on a special granite plaque and mementos will be
presented during Diamond Jubilee.`,
    },
    {
      type: `Rs.5.00 lakh and
Above`,

      perk: `Their photos will be displayed in the hall and published in the website,
names will be engraved on a special granite plaque and mementos will be
presented during Diamond Jubilee.`,
    },
    {
      type: `Rs.1.00 lakh and
Above`,

      perk: `Their photos will be displayed in the hall and published in the website,
names will be displayed on a special panel and mementos will be
presented during Diamond Jubilee.`,
    },
    {
      type: `5. Rs.50000/= and
Above`,

      perk: `Their photos will be published in the website, names will be displayed on a
special panel and mementos will be presented during Diamond Jubilee.`,
    },

    {
      type: `Rs.5000/= and
Above`,
      perk: `Their photos will be published in the website and the names will be
displayed on a special panel.`,
    },
  ];

  var renderData = data.map((e) => {
    return (
      <tr>
        <td style={{ padding: 10, fontWeight: "bold" }}>{e.type}</td>
        <td style={{ padding: 10 }}>{e.perk} </td>
      </tr>
    );
  });
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 25, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            color: "#3ee771",
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
          }}
        >
          RECOGITION OF DONORS
        </h2>
        <table
          className=""
          border="2px"
          width="100%"
          style={{ padding: "20px" }}
        >
          {renderData}
        </table>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default DonorRecognition;
