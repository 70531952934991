import React from "react";
import db from "../firebase.config";
export const useGetData = (collection) => {
  const [finalData, setData] = React.useState([]);
  React.useEffect(async () => {
    db.collection("news_info")
      .doc(collection)
      .get()
      .then((snap) => {
        let arr = [];
        snap.data()["list"].forEach((ele) => arr.push(ele));
        setData(arr);
      });
  }, [db]);

  return [finalData];
};

export const useGetDonorsData = () => {
  const [finalData, setData] = React.useState([]);
  React.useEffect(() => {
    db.collection("donors")
      .doc("donors")
      .get()
      .then((snap) => {
        let arr = [];
        snap.data()["donorsList"].forEach((ele) => arr.push(ele));
        setData(arr);
      });
  }, [db]);

  return [finalData];
};

export const useGetEnFacultyData = () => {
  const [finalData, setData] = React.useState([]);
  React.useEffect(() => {
    db.collection("faculty")
      .where("lang", "==", "english")
      .get()
      .then((snap) => {
        let arr = [];
        snap.docs.forEach((ele) => arr.push(ele.data()));
        setData(arr);
      });
  }, [db]);

  return [finalData];
};

export const useGetKnFacultyData = () => {
  const [finalData, setData] = React.useState([]);
  React.useEffect(() => {
    db.collection("faculty")
      .where("lang", "==", "kannada")
      .get()
      .then((snap) => {
        let arr = [];
        snap.docs.forEach((ele) => arr.push(ele.data()));
        setData(arr);
      });
  }, [db]);

  return [finalData];
};
