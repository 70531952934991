import React from "react";
import Fab from "../../components/fab.js";
import Footer from "../../components/footer.js";
import Header from "../../components/header.js";

const GreenInitiatives = () => {
  return (
    <div>
      <Header />

      <div
        data-aos="fade-down"
        class="cardStyle"
        style={{ paddingBottom: 200, marginTop: "100px" }}
      >
        <h2
          id="title"
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            textAlign: "center",
            color: "#3ee771",
          }}
        >
          Green Initiatives
        </h2>
        <p className="paragraph" style={{ textAlign: "justify" }}>
          {" "}
          The society has been conferred with Wondorla Green School Award 2013
          for its Green Initiatives. It has been involved in creating awareness
          among the public and students regarding environmental issues and
          cleanliness. The school has initiated development of a Medicinal
          Plants Garden with the help of Western Ghat Task Force, Govt of
          Karnataka. It has also arranged many workshops and seminars on the
          environmental issues. It has built a bio-gas plant of 150 cft to
          utilise the cooking waste. Installed Solar Water Heaters and Lights
          and also using energy saving CFL lamps. It has published book on
          rejuvenation of Betta Land of this region. It is striving to construct
          permanent RCC Column and MS Sheet Gate Structure Check Dams for Water
          Conservation and Water Recycling. The society confers “Vrukshalakshya”
          Environment Award biennially to an eminent environment volunteer..{" "}
        </p>
      </div>
      <Fab />
      <Footer />
    </div>
  );
};

export default GreenInitiatives;
