import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HashRouter, Route, Switch } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Intro from "./pages/about/introduction.js";
import Management from "./pages/about/management";
import Hostel from "./pages/projects/hostel.js";
import Academics from "./pages/activities/academics.js";
import Kaushalya from "./pages/activities/kaushalya.js";
import GreenInitiatives from "./pages/activities/green_initiatives.js";
import Donors from "./pages/about/donors.js";
import DonorRecognition from "./pages/donation/donor_recognition";
import Projects from "./pages/projects/projects.js";
import Faculty from "./pages/about/faculty.js";
import News from "./pages/news_events/news.js";
import Gallery from "./pages/news_events/gallery";
import Upcoming from "./pages/news_events/upcoming";
import Founders from "./pages/about/founders";
import AcademicProjects from "./pages/projects/academics.js";
import Transportation from "./pages/projects/transportation.js";
import Cultural from "./pages/projects/cultural.js";
import SportsAndInfra from "./pages/projects/sports.js";
import BankDetails from "./pages/donation/bank_details";
import Appeal from "./pages/donation/appeal.js";
import RecurringExp from "./pages/recurring/recurring_expenses";
import Endowments from "./pages/recurring/endowments";
import RegistrationDetails from "./pages/about/registration_details";
import ValueEducation from "./pages/activities/value_edu.js";
import SportsAndGames from "./pages/activities/sports_and_games";
import CulturalActivities from "./pages/activities/cultural_acts";
import Annapoorna from "./pages/activities/annapoorna";
import DonorsList from "./pages/donation/donors_list";

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/introduction" component={Intro} />
      <Route path="/management" component={Management} />
      <Route path="/faculty" component={Faculty} />
      <Route path="/hostel" component={Hostel} />
      <Route path="/academics" component={Academics} />
      <Route path="/kaushalya" component={Kaushalya} />
      <Route path="/green-initiatives" component={GreenInitiatives} />
      <Route path="/projects" component={Projects} />
      <Route path="/news" component={News} />
      <Route path="/upcoming" component={Upcoming} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/donors" component={Donors} />
      <Route path="/founders" component={Founders} />
      <Route path="/academic-projects" component={AcademicProjects} />
      <Route path="/transportation" component={Transportation} />
      <Route path="/cultural" component={Cultural} />
      <Route path="/sports-infra" component={SportsAndInfra} />
      <Route path="/bank" component={BankDetails} />
      <Route path="/appeal" component={Appeal} />
      <Route path="/recurring" component={RecurringExp} />
      <Route path="/endowments" component={Endowments} />
      <Route path="/recog-donors" component={DonorRecognition} />
      <Route path="/registration-details" component={RegistrationDetails} />
      <Route path="/value-edu" component={ValueEducation} />
      <Route path="/sports-games" component={SportsAndGames} />
      <Route path="/cultural-activity" component={CulturalActivities} />
      <Route path="/annapoorna" component={Annapoorna} />
      <Route path="/donors-list" component={DonorsList} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
